<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading || !isColaborador">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-account-group</v-icon>
        Clientes
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="clientes"
          :headers="headers"
          :items="clientes"
          :items-per-page="10"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="goToCliente"
        >
          <template v-slot:item.status="{ item }">
            {{ item.status | status }}
          </template>
          <template v-slot:item.data_cadastro="{ item }">
            {{ item.data_cadastro | dateFormat("dd/MM/yyyy") }}
          </template></v-data-table
        >
      </v-card-text>
    </v-card>
    <ClienteColaborador
      v-if="dialogCliente"
      :dialogCliente.sync="dialogCliente"
      :item="cliente"
    />
  </v-container>
</template>

<script>
import { fetchClientes } from "@/api/colaborador/clientes.js";
import { mapState, mapGetters } from "vuex";
export default {
  name: "Clientes",

  components: {
    ClienteColaborador: () => import("./components/ClienteColaborador.vue"),
  },

  data() {
    return {
      clientes: [],
      cliente: {},
      loading: false,
      dialogCliente: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Razão social",
          value: "razao_social",
        },
        {
          text: "Nome fantasia",
          value: "nome_fantasia",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Cadastro",
          value: "data_cadastro",
        },
      ];
    },

    ...mapGetters("Usuario", ["isColaborador"]),
    ...mapState("Usuario", {
      colaborador_id: (state) => state.usuario.colaborador_id,      
    }),
  },

  methods: {
    goToCliente(item) {
      this.cliente = { ...item };
      this.dialogCliente = true;
    },
    getClientes() {
      this.loading = true;
    
      return fetchClientes(`?colaborador_id=${this.colaborador_id}`).then(
        (response) => {
          this.clientes = response;
          this.loading = false;
        }
      );
    },
  },

  async mounted() {
    if (this.colaborador_id) {
      await this.getClientes();
    }
  },
};
</script>

<style lang="scss"></style>
